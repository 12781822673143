export const initAdsense = () => {
  if (typeof document !== "undefined" && typeof window !== "undefined") {
    // Only run on /blog or /blog/* paths
    const pathname = window.location.pathname
    const isBlogPage = pathname === "/blog" || pathname === "/blog/" || pathname.startsWith("/personal-trainer-liverpool/")

    if (!isBlogPage) return // Exit if not on blog

    // Check for cookie consent
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=")
      acc[name] = value
      return acc
    }, {})

    if (cookies["gatsby-gdpr-google-adsense"] === "true") {
      const adsScript = document.createElement("script")
      adsScript.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1047075162249554"
      adsScript.async = true
      adsScript.crossOrigin = "anonymous"
      document.head.appendChild(adsScript)
    }
  }
}
